import Vue from "vue";
import Vuex from "vuex";
import DPCMember from "./Modules/DPCMember/index.js";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import api from "../services/api.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    namespaced: true,
    DPCMember,
  },
  state: {
    showSidebar: false,
    breadcrumb: "",
    filterMenu: false,
    tab: true,
    path: "",
    sideBarTitle: "",
    sideBarDesc: "",
    showNavItem: "",
    showEmailOverlay: false,
    clearingHouseDetails: null,
    eligibilityMember: null,
    mobileMenuState: false,
    selectedProvider: null,
    selectedEmployer: null,
    componentState: true,
    documentTitle: null,
    dependentId: "",
    successEnrollment: false,
    searchBy: "normal", // T.N. used in pagination to determine which method to use. enum: ['normal', 'filters'],
    marketFilters: {
      selectedCategories: [],
      selectedGroupAndOrganizations: [],
      selectedSpecialities: [],
      selectedGenders: [],
      selectedServiceTypeCodes: [],
    },
    addressQuery: "",
    marketplace: [],
    clinicians: [],
    clinics: [],
    tabName: "all",
    pageNumber: 1,
    totalPages: 0,
    totalPagesBE: 0, // we need this when we recalc totalPages for tab=clinicians, otherwise we would lose state if going back tab=all/clinics
    pageLimit: 6,
    mapCenter: { lat: 0, lng: 0 },
    mapPins: [],
    userLocation: { lat: 0, lng: 0 },
    selectedClinicLocation: { lat: 0, lng: 0 },
    familyMemberData: [],
    loader: false,
    searchedAddress: "",
    isCustomURL: false,

    memberInfo: {}, //based on new refactoring #553
    dependentInfo: {},
    memberPlan: "", // MEM 464 | Populate plan field for second dependent being added
  },
  getters: {
    getMobileMenuState(state) {
      return state.mobileMenuState;
    },
    getSelectedProvider(state) {
      return state.selectedProvider;
    },
    getSelectedEmployer(state) {
        return state.selectedEmployer;
    },
    getComponentState(state) {
      return state.componentState;
    },
    getTitle(state) {
      return state.documentTitle;
    },
    getDependentId(state) {
      return state.dependentId;
    },
    getEndrollmentStatus(state) {
      return state.successEnrollment;
    },
  },
  mutations: {
    setFamilyMemberData(state, data) { // This is for reconfirming member eligibility
      state.familyMemberData = data;
    },
    openMobileMenu(state, openMenu) {
      state.mobileMenuState = openMenu;
    },
    setSelectedProvider(state, payload) {
      state.selectedProvider = payload;
    },
    setSelectedEmployer(state, payload) {
        state.selectedEmployer = payload;
    },
    setComponentState(state, data) {
      state.componentState = data;
    },
    setTitle(state, data) {
      state.documentTitle = data;
    },
    setDependent(state, data) {
      state.dependentId = data;
    },
    setEnrollmentStatus(state, data) {
      state.successEnrollment = data;
    },
    setTab(state, tab) {
      state.tabName = tab;
      if (tab == "clinicians") {
        state.totalPagesBE = state.totalPages; // this way we don't lose state in the else step
        state.totalPages = Math.ceil(state.clinicians.length / 6) || 0;
      } else {
        state.totalPages = state.totalPagesBE || 0;
      }
    },
    setSearchHistory(state, data) {
      state.marketplace = [...data.clinics, ...data.clinicians];
      state.clinics = data.clinics;
      state.clinicians = data.clinicians;
      // state.totalPages = data.totalPages || 0 //temp solution
      //for the pagination part - for clinicians we need new check
      if (state.tabName == "all" || state.tabName == "clinics") {
        state.totalPages = data.totalPages || 0;
        // console.log('totalpage',data)
      } else {
        state.totalPages = Math.ceil(state.clinicians.length / 6) || 0;
      }
      state.pageNumber = data.currentPage || 1;
      state.totalPagesBE = data.totalPages || 0; // this way we don't lose state when changing tab back to all/clinics
    },
    setMarketFilters(state, data) {
      state.marketFilters = data;
    },
    setPage(state, data) {
      state.pageNumber = data || 1;
    },
    setSearchBy(state, data) {
      state.searchBy = data;
    },
    setMapCenter(state, data) {
      state.mapCenter = {
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
      };
    },
    setMapPins(state, data) {
      state.mapPins = data;
    },
    setUserLocation(state, data) {
      state.userLocation = data;
      if (
        !state.mapCenter ||
        state.mapCenter.lng == 0 ||
        state.mapCenter.lat == 0
      )
        state.mapCenter = state.userLocation;
    },

    setLoader(state, data) {
      state.loader = data;
    },
    setSearchAddress(state, data) {
      state.searchedAddress = data;
    },
    setCustomCompany(state, data) {
      state.isCustomURL = data;
    },
    setSelectedClinicLocation(state, data) {
      state.selectedClinicLocation = data;
    },
    showSidebar(state, data) {
      state.showSidebar = data;
    },
    setMemberInfo(state, data) {
      state.memberInfo = data;
    },
    setMemberPlan(state, data) {
      state.memberPlan = data;
    },
    setDependentInfo(state, data) {
        state.dependentInfo = data;
    },
  },
  actions: {
    async getMarketplace(context, query) {
      let state = context.state;
      // console.log('dependent in storese',state)
      // let queryParams = { ...query, page: state.pageNumber, limit: state.pageLimit }
      // if(state.userLocation)queryParams = { ...queryParams, lng: state.userLocation.lng, lat: state.userLocation.lat }
      // let url = `/member/app/market?${new URLSearchParams(queryParams).toString()}`
      // console.log('store getMarketplace', url, queryParams)
      let url = `/member/app/${
        state.dependentId ? "family/member/" + state.dependentId + "/" : ""
      }market`;

      let queryParams = {
        ...query,
        page: state.pageNumber,
        limit: state.pageLimit,
      };

      if (state.userLocation) {
        queryParams = {
          ...queryParams,
          lng: state.userLocation.lng,
          lat: state.userLocation.lat,
        };
      }

      url += `?${new URLSearchParams(queryParams).toString()}`;

      context.commit("setLoader", true);
      return api()
        .get(url)
        .then((res) => {
          if (res && res.data) {
            context.commit("setSearchHistory", res.data);
            context.commit("setSearchBy", "normal");
            // NOTES: we don't center map after market search (see also other functions)
            // if(res.data.clinics.length > 1){
            //   const clinic = res.data.clinics[0]
            //   if(clinic.coordinates)context.commit('setMapCenter', { lng: parseFloat(clinic.coordinates.lng), lat: parseFloat(clinic.coordinates.lat) })
            // } else context.commit('setMapCenter', state.userLocation)
          }
        })
        .catch((err) => {
          if (err.response) {
            return err.response.status
          }
          return err
        })
        .finally(() => {
          context.commit("setLoader", false);
        });
    },

    searchMarketplaceByFilters(context, filters) {
      context.commit("setMarketFilters", filters);
      let state = context.state;
      let search = {
        name: filters.searchByName, // New Filter Update
        distance: filters.searchDistance, // New Filter Update
        serviceTypes: filters.serviceTypes, // BH indicates pediatric service
        specialities: filters.selectedSpecialities,
        gender: filters.selectedGenders,
        newPatients: filters.newPatients,
        organizations: filters.selectedGroupAndOrganizations,
        employer: filters.employer,
        // types: filters.selectedCategories,
        // credentials: filters.selectedCredentials,
        // serviceTypeCodes: filters.selectedServiceTypeCodes,
      };
      if (state.searchedAddress && state.searchedAddress != "") {
        search.address = state.searchedAddress;
      }

      // let url = `/member/app/market/search?page=${context.pageNumber}&limit=${context.pageLimit}`
      // let queryParams = { ...data.query, page: state.pageNumber, limit: state.pageLimit }
      let queryParams = { page: state.pageNumber, limit: state.pageLimit, userZipCode: filters.userZipCode };
      if (filters.query && filters.query.company) {
        queryParams.company = filters.query.company;
      }
      if (state.userLocation)
        queryParams = {
          ...queryParams,
          lng: state.userLocation.lng,
          lat: state.userLocation.lat,
        };
      let url = `/member/app/market/search?${new URLSearchParams(
        queryParams
      ).toString()}`;

      context.commit("setLoader", true);
      api()
        .post(url, { search })
        .then((res) => {
          if (res && res.data) {
            context.commit("setSearchHistory", res.data);
            context.commit("setSearchBy", "filters");
            if (res.data.clinics.length > 0) {
              let selectedClinic;
              for (const clinic of res.data.clinics) {
                if (clinic.coordinates) {
                  selectedClinic = clinic;
                  // console.log("selectedClinic", selectedClinic);
                  break;
                }
              }
              if (selectedClinic) {
                context.commit("setMapCenter", selectedClinic.coordinates);
                // console.log("cliniccccc");
              } else {
                context.commit("setMapCenter", context.userLocation);
              }
            }
          }
        })
        .catch((err) => {
          console.log("searchMarketplaceByFilters() Error: ",err);
        })
        .finally(() => {
          context.commit("setLoader", false);
        });
    },

    searchMarketplaceByAddress(context, data) {
      let search = { address: data.address, ...data.filters };
      let state = context.state;
      // let url = `/member/app/market/search?page=${state.pageNumber}&limit=${state.pageLimit}`

      let queryParams = {
        ...data.query,
        page: state.pageNumber,
        limit: state.pageLimit,
      };

      // console.log("queryParams,queryParams", data, queryParams);
      if (state.userLocation)
        queryParams = {
          ...queryParams,
          lng: state.userLocation.lng,
          lat: state.userLocation.lat,
        };
      let url = `/member/app/market/search?${new URLSearchParams(
        queryParams
      ).toString()}`;

      context.commit("setLoader", true);
      api()
        .post(url, { search })
        .then((res) => {
          if (res && res.data) {
            context.commit("setSearchHistory", res.data);
            context.commit("setSearchBy", "address");
            // if(res.data.clinics.length > 1){
            //   const clinic = res.data.clinics[0]
            //   if(clinic.coordinates)context.commit('setMapCenter', clinic.coordinates)
            // }
            // else {
            //   fetch(geocodeUrl)
            //   .then((response) => response.json())
            //   .then((data) => {
            //     if (data.status === 'OK' && data.results.length > 0) {
            //       // if(data && data.results && data.results[0] && data.results[0].geometry && data.results[0].geometry.location)
            //       //     context.commit('setMapCenter', data.results[0].geometry.location)
            //     }
            //   })
            //   .catch((error) => {
            //     console.error('Geocoding error:', error);
            //   });
            // }
          }
        })
        .catch((err) => {
          console.log("searchMarketplaceByAddress() Error: ", err);
        })
        .finally(() => {
          context.commit("setLoader", false);
        });
    },

    clearMapPins(context) {
        context.commit("setMapPins", []);
    },
    getAllPlaces(context, data) {
      if(data && data.alias) {
        // If Custom DPC link
        // console.log("Custom DPC link: ", alias);
        let url = `/member/app/market/location/pins?company=${alias}${data ? `&${data.query}` : ''}`;
        api()
          .get(url)
          .then((res) => {
            if (res && res.data) context.commit("setMapPins", res.data);
          })
          .catch((err) => {
            console.log("getAllPlaces() Error: ", err);
          });
      }
      else {
        // Normal Search
        // console.log("Normal Search");
        let url = `/member/app/market/location/pins${data ? `?${data.query}` : ''}`;
        api()
          .get(url)
          .then((res) => {
            if (res && res.data) context.commit("setMapPins", res.data);
          })
          .catch((err) => {
            console.log("getAllPlaces() Error: ", err);
          });
      }
      
    },
    getMemberInfo(context) {
      let url = `/member/app/member/info`;
      api()
        .get(url)
        .then((res) => {
          if (res && res.data) context.commit("setMemberInfo", res.data);
        })
        .catch((err) => {
          console.log("getMemberInfo() Error: ",err);
        //   context.commit("setMemberInfo", {});
        });
    },
    getDependentInfo(context, memberId) {
        api()
            .get(`/member/app/family/member/${memberId}`)
            .then((res) => {
                if (res && res.data) {
                    context.commit("setDependentInfo", res.data);
                }
            })
            .catch((err) => {
                console.log("err", err);
            })
 
      },
    // Add a separate async action to prevent breaking changes
    async asyncGetMemberInfo(context) {
        let url = `/member/app/member/info`;
        await api()
          .get(url)
          .then((res) => {
            if (res && res.data) context.commit("setMemberInfo", res.data);
          })
          .catch((err) => {
            console.log("asyncGetMemberInfo() Error: ",err);
            context.commit("setMemberInfo", {});
          });
      },
  },
});
