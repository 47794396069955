<template>
  <div class="new_register_page">
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loading" />
    
    <!-- CONTENTS -->
    <div class="register-page-container">

      <div class="register-page-form" v-if="!takeUserEmail">

        <!-- FORM SCROLLING -->
        <div class="form_scorlling_register">

        <!-- HEADER -->
        <div class="mob_header_cont" >

          <!-- HEADER DSEKTOP -->
          <div class="mob_header hide_on_mobile">
            <div class="mob_header_div mob_div_pl display_flex_left">
              <!-- <img src="/svg/icon_white_arrow_left.svg" role="button" @click="$router.go(-1)"><br> -->
            </div>
            <div class="mob_header_div mob_div_pr display_flex_right">
              <img src="/apalywhite.svg" class="mob_header_logo"/>
            </div>
          </div>

          <!-- HEADER MOBILE -->
          <div class="mob_header hide_on_desktop">
            <div class="mob_header_div display_flex_left">
              <!-- <img src="/svg/icon_white_arrow_left.svg" role="button" @click="$router.go(-1)"><br> -->
            </div>
            <div class="mob_header_div display_flex_right">
              <img src="/apalywhite.svg" class="mob_header_logo"/>
            </div>
          </div>

          <!-- STEPS NO SELECTED PROVIDER-->
          <div class="mob_step_cont" v-if="!selectedProvider">
            <div class="mob_step">
              <div class="mob_step_num mob_step_active">1</div>
              <div class="mob_step_text">Personalize <br> your search</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">2</div>
              <div class="mob_step_text">Select <br> Provider</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">3</div>
              <div class="mob_step_text">Verify your <br> eligibility</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">4</div>
              <div class="mob_step_text">Schedule your<br> first visit</div>
            </div>
          </div>

          <!-- STEPS WITH SELECTED PROVIDER-->
          <div class="mob_step_cont" v-else>
            <div class="mob_step">
              <div class="mob_step_num mob_step_done">1</div>
              <div class="mob_step_text">Select <br> Provider</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num mob_step_active">2</div>
              <div class="mob_step_text">Create <br> account</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">3</div>
              <div class="mob_step_text">Verify your <br> eligibility</div>
            </div>
            <div class="mob_step_gap"></div>
            <div class="mob_step">
              <div class="mob_step_num">4</div>
              <div class="mob_step_text">Schedule your<br> first visit</div>
            </div>
          </div>

        </div>

        <!-- DPC/PROVIDER DETAILS -->
        <div class="mob_header_bg mob_adjust_margin" v-if="selectedProvider">
          <div class="mob_header_provider_cont">
            <div class="mob_header_title" >You've selected:</div>
            <div class="provider_container">
              <!-- LOGOS -->
              <div class="provider_logo_container">
                <div class="provider_logo" v-if="selectedProvider && selectedProvider.logo" :style="`background-image:url(${selectedProvider.logo})`"></div>
                <div class="provider_logo" v-else :style="`background-image:url('/tpa/clinicsAndOrganizations.svg')`"></div>
              </div>

              <span class="provider_location">
                <span class="font-16 provider_title">{{ selectedProvider.companyName }}</span> 
                <br /> 
                <span class="font-12 provider_location">
                  {{ selectedProvider.contact?.address1 }},
                  {{ selectedProvider.contact?.city }},
                  {{ selectedProvider.contact?.state }},
                  {{ selectedProvider.contact?.zipCode }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <!-- FORM -->
        <div :class="`form_desk_up mob_register_form ${!selectedProvider ? 'mob_adjust_margin':''}`">
          <v-form v-model.trim="valid" ref="form" class="authentication-page-right-side-form register_div_size">
            <p class="font-size-32 pb-0 mb-0 personalize_search_text" style="font-size: 24px; font-weight: bold; ">
                Let's verify your plan eligibility 
            </p>
            <p class="pb-0 mb-5 mt-3 personalize_search_text" style="font-size: 16px;">
                Please enter your info so we can verify that your employer covers the cost of APC
            </p>
            <div>
              <label class="label-color">Select your employer</label>
              <v-select
                v-model="selectedEmployerInfo"
                :items="employers"
                placeholder="Select"
                @change="selectedEmployer"
                item-value="id"
                return-object
                :rules="requiredRules"
                class="custom-v-select"
                outlined dense
                no-data-text="No employer found"
                @click="focusSearchInput()"
                v-click-outside="onClickOutside"
              >

                <template v-slot:prepend-item>
                  <div class="custom_select_input_container">
                    <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search employer here" @input="searchingEmployers"/>
                  </div>
                </template>

                <!-- Show Selected Employer -->
                <template #selection="{ item }">
                  {{ item.companyName }}
                </template>

                <!-- List of Employers -->
                <template #item="{ item }">
                  <div>{{ item.companyName }}</div>
                  <!-- <div :class="{ 'active-item': 'active' }">{{ item.companyName }} </div> -->
                </template>

                <template #item-disabled="{ item }">
                  <div >{{ item.companyName }}</div>
                </template>
                
              </v-select>
              
            </div>
            <div class="mt-0 mb-0">
              <label class="label-color"> Enter your e-mail</label>
              <v-text-field placeholder="E-mail" :class="emailErrorMessage ? 'mb-1' : 'mb-1'" outlined dense
                v-model="user.email" :rules="emailRules"
                style="border-radius: 8px"></v-text-field>
              <p class="input-error-message mt-0 mb-0 pa-0 " 
                v-if="emailErrorMessage &&
                emailErrorMessage.reason == 'email' &&
                emailErrorMessage.message ==
                'This email is already registered'
                " style="color: red; font-size: 10pt">
                {{ emailErrorMessage.message }}. Use another one or
                <a @click="$router.push('/login')">login</a>
              </p>
              <p class="input-error-message mt-0 mb-0 pa-0" v-else-if="emailErrorMessage && emailErrorMessage.reason == 'email'
                " style="color: red; font-size: 10pt">
                {{ emailErrorMessage.message }}
              </p>
              <p class="input-error-message mt-0 mb-0 pa-0" v-else-if="emailErrorMessage && emailErrorMessage.reason == 'company'
                " style="color: red; font-size: 10pt">
                {{ emailErrorMessage.message }}
              </p>
              <!-- <p class="input-error-message mt-0 mb-0 pa-0 tester" v-else-if="emailErrorMessage"
                style="color: red; font-size: 10pt">
                {{ emailErrorMessage }}
              </p> -->
            </div>
            <div class="mb-5 mt-0 checkbox_accept_terms">
              <div class="font-12 black-text mt-0 pt-0" slot="label">
                <!-- <span class="font-12 black-text mt-0 pt-0">
                  By clicking "Continue" you accept the terms of the
                  <a class="text-underline" @click="openPdfModal()">Apaly Health Terms of Use Agreement</a>.
                </span> -->
                <v-checkbox v-model="agreeeToTermsofUse" required>
                  <template v-slot:label>
                    <span class="font-12 black-text mt-0 pt-0">
                      By clicking "Continue" you accept the terms of the
                      <a class="text-underline" @click="openPdfModal()">Apaly Health Terms of Use Agreement</a>.
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="text-end justify-end d-flex mt-0 pt-0">
              <!-- <v-btn class="pt-0 mt-3 form-button no_box_shadow" color="#0069F3" :disabled="!valid || !emailStatus || !agreeeToTermsofUse"
                @click="continueFlow()">Continue</v-btn> -->
                <v-btn class="pt-0 mt-5 form-button no_box_shadow" color="#0069F3" :disabled="!valid || !agreeeToTermsofUse"
                @click="continueFlow()">Continue</v-btn>
            </div>
          </v-form>
        </div>

        </div>


        <!-- ALREADY HAVE AN ACCOUNT? -->
        <div class="sign_footer_position">
          <div class="sign_footer">
            <span>Already have an account?</span>
            <strong class="link-text" @click="$router.push('/login')">
              &nbsp; Login &nbsp;
              <img src="/svg/registerArrow.svg" />
            </strong>
          </div>
        </div>
        
      </div>

      <div class="take_user_email_container" v-else>
        <div class="dialog_layout">
          <div class="dialog_content">
            <div class="take_user_email_logo">
              <img src="apaly-logo.png" alt="apaly logo" width="auto" height="60px">
            </div>
            <div class="dialog_title dialog_second">
              Let us know how to contact you
            </div>
            <div class="dialog_subtext dialog_second">
              We are going to notify you as soon as your employer offers this benefit!
            </div><br>
            <div class="take_user_email_content">
              <!-- Email -->
              <div>
                <label class="label-color"> Email</label> 
                <v-text-field placeholder="Insert Email" :class="emailErrorMessage ? 'mb-1' : 'mb-1'" outlined dense
                  @input="delayCheckmail()" v-model="user.email" :rules="emailRules"
                  style="border-radius: 8px"></v-text-field>
                <p class="input-error-message mt-0 mb-0 pa-0 " 
                  v-if="emailErrorMessage &&
                  emailErrorMessage.reason == 'email' &&
                  emailErrorMessage.message ==
                  'This email is already registered'
                  " style="color: red; font-size: 10pt">
                  {{ emailErrorMessage.message }}.
                </p>
                <p class="input-error-message mt-0 mb-0 pa-0" v-else-if="emailErrorMessage && emailErrorMessage.reason == 'email'
                  " style="color: red; font-size: 10pt">
                  {{ emailErrorMessage.message }}
                </p>
                <p class="input-error-message mt-0 mb-0 pa-0" v-else-if="emailErrorMessage && emailErrorMessage.reason == 'company'
                  " style="color: red; font-size: 10pt">
                  {{ emailErrorMessage.message }}
                </p>

              </div>
              <!-- Select Employer -->
              <div :class="emailErrorMessage?.message ? `mt-3`:`mt-0`">
                <label class="label-color">Employer</label>
                <v-select
                  v-model="selectedEmployerInfo"
                  :items="employers"
                  placeholder="Select which company you're working for"
                  @change="selectedEmployer"
                  item-value="id"
                  return-object
                  :rules="requiredRules"
                  class="custom-v-select"
                  outlined dense
                  no-data-text="No employer found"
                  @click="focusSearchInput()"
                  v-click-outside="onClickOutside"
                >

                  <template v-slot:prepend-item>
                    <div class="custom_select_input_container">
                      <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search employer here" @input="searchingEmployers"/>
                    </div>
                  </template>

                  <!-- Show Selected Employer -->
                  <template #selection="{ item }">
                    {{ item.companyName }}
                  </template>

                  <!-- List of Employers -->
                  <template #item="{ item }">
                    <div>{{ item.companyName }}</div>
                    <!-- <div :class="{ 'active-item': 'active' }">{{ item.companyName }} </div> -->
                  </template>

                  <template #item-disabled="{ item }">
                    <div >{{ item.companyName }}</div>
                  </template>
                  
                </v-select>
              </div>

              <!-- I Agree -->
              <v-checkbox
                class="dialog_checkbox mb-10 mt-0"
                hide-details
                v-model="allowApalyToContact"
                label="I agree to be contacted by Apaly"
                :true-value="true"
                :false-value="false"
              >
              </v-checkbox>
              
            </div>
            <div class="dialog_buttons_block">
              <button 
                class="dialog_single_button dialog_btn_primary_3 dialog_btn_floatLeft" 
                @click="saveWaitlist()" 
                :disabled="!user?.email || !allowApalyToContact || !selectedEmployerInfo || emailErrorMessage?.message || !emailStatus"
              >
                Notify me
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- ERROR MESSAGE -->
      <v-col v-if="error.length > 0" class="register-page-error-box mt-3 mb-3 col-md-12">
        <v-alert dense outlined type="error">
          {{ error }}
        </v-alert>
      </v-col>

    </div>

    <!-- DIALOGS / MODALS -->
    <v-dialog v-model.trim="pdfDialog">
      <v-card>
        <span role="button" align="end" class="pt-0 pr-0">
          <h1 class="font-weight-bold mr-4" @click="pdfDialog = false">X</h1>
        </span>
        <v-card-text class="success-modal pt-5">
          <object :data="pdfUrl" type="application/pdf" width="100%" height="1000"></object>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="employerCheck" width="510" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="confirmEligibility()">
              <v-icon>mdi-close</v-icon>
            </p>
          </span>
          <div class="display">
            <div class="text-center">
              <img src="/svg/firework.svg" height="140px" />
            </div>
            <div style="
                font-size: 24px;
                color: #333333;
                font-weight: 400;
                line-height: 24px;
              ">
              Congratulations! <br />
              {{ user.searchEmp }} offers this benefit!
            </div>
            <div style="font-size: 16px; color: #333333" class="mt-2">
              Now let's confirm your eligibility on your employer's health plan
            </div>
          </div>

          <div class="text-center text--white mt-6">
            <v-btn color="#0069F3" class="email-sent text--white" @click="confirmEligibility()">
              Confirm eligibility
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG?MODAL -->

    <!-- Employer does not offer this benefit -->
    <v-dialog v-model="continueModal" width="550px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="continueModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Unfortunately, your employer does not currently offer Advanced Primary Care (APC).
          </div>
          <div class="dialog_subtext dialog_second">
            We will reach out to your employer to see if they're interested in offering APC.  Please click <b>Notify Me</b> below, and we will let you know as soon as your employer signs up.
          </div><br>
          <div class="dialog_buttons_block">
            <!-- <button class="dialog_double_button dialog_btn_primary_3 dialog_btn_floatLeft" @click="getUserEmail()">
              Enter e-mail
            </button> -->
            <button class="dialog_double_button dialog_btn_primary_3 dialog_btn_floatRight" @click="notifyMe()">
              Notify Me
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="notifyModal2" width="510" persistent>
        <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon
          role="button"
          @click="notifyModal2 = false"
          v-text="'mdi-close'"
          slot="end"
        ></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="text-center mb-5">
          <img src="/svg/email_sent.svg" />
        </div>
        <div class="dialog_title dialog_second">
            Awesome..! We'll notify you as soon as your employer signs up.
        </div>
        <!-- <div class="dialog_subtext dialog_second">
          We'll notify you as soon as this clinic is available and accepting new patients.
        </div> -->
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary mt-5" @click="notifyModal2 = false" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <v-dialog v-model="notifyModal" width="500px" persistent>
      <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon
          role="button"
          @click="addedToWaitlist = false"
          v-text="'mdi-close'"
          slot="end"
        ></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="text-center mb-5">
          <img src="/svg/email_sent.svg" />
        </div>
        <div class="dialog_title dialog_second">
            Awesome..! We'll notify you as soon as your employer signs up.
        </div>
        <!-- <div class="dialog_subtext dialog_second">
          We'll notify you as soon as this clinic is available and accepting new patients.
        </div> -->
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary mt-5" @click="$router.push('/marketplace')" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- Generic Error Modal -->
    <v-dialog v-model="genError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="genError = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            {{ genErrorMess ? genErrorMess:'Oops. Something went wrong' }}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="genError = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <errorModal v-if="somethingWrong" :someThingWentWrong="somethingWrong" ref="error_modal" ></errorModal>

  </div>
</template>

<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "@/services/api";
import { mapState } from 'vuex';
import errorModal from '../../../Common/SomethingWentWrong.vue'
import vClickOutside from 'v-click-outside';
import Mixpanel from "mixpanel-browser";
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';

export default {
  components: {
    errorModal,
    FullscreenLoader
  },
  data() {
    
    return {
      agreeeToTermsofUse: false,
      genError: false,
      genErrorMess: "",
      loading: false,
      allowApalyToContact: false,
      takeUserEmail: false, 
      somethingWrong: false,
      searchTerm: '',
      selectedEmployerInfo: null,
      continueModal: false,
      notifyModal: false,
      employersCopy: [],
      allEmployers: [],
      employerCheck: false,
      selectedProvider: null,
      checkEmailDialog: false,
      user: {},
      showPassword: false,
      showPasswordConfirmation: false,
      valid: false,
      emailErrorMessage: null,
      emailStatus: false,
      pdfUrl: "",
      pdfDialog: false,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
          "Please enter a valid e-mail",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      passwordMatches: true,
      error: "",
      registerSemafor: true,
      employer: "",
      employers: [],
      searchEmp: "",
      showEmployer: false,
      empl: null,
      dataToSend: null,
      typingTimuot: null,
      notifyModal2: false,
      employerFromMarketplace: null,
    };
  },
  computed: {
    ...mapState({
      registrationData: state => state.registrationData,
      memberInfo: (state) => state.memberInfo,
    }),
    // filteredEmployers() {
    //     return this.employers.filter((employer) =>
    //     employer && employer.companyName &&
    //     employer.companyName.toLowerCase().includes(this.user.searchEmp.toLowerCase())
    //   );
    // },

  },
  mounted() {
    this.employersCopy = [...this.employers];
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    saveWaitlist() {
      this.loading = true;
      let params = {
        email: this.user.email,
        location: this.selectedEmployerInfo?.id ? this.selectedEmployerInfo.id : null,
      };

      // If this.$store.getters.getDependentId has ID (Dependent ID), then use it. Else, use the memberInfo.id (Subscriber's ID)
      const memberID = this.$store.getters.getDependentId ? this.$store.getters.getDependentId : this.memberInfo.id;
      api()
        .post(`/member/app/join/wait/list`, params)
        .then((res) => {
          // console.log("Notify when empoyer offers benefits: Success");
          // console.log(res.data);
          this.notifyModal = true; // IF successful
          this.loading = false;
          this.user.email = "";
        })
        .catch((err) => {
          // console.log("Notify when empoyer offers benefits: Error");
          // console.log(err);
          // console.log(err.response.data);
          this.genError = true;
          this.genErrorMess = err.response.data.message ? err.response.data.message : err.response.data;
          this.loading = false;
        });
    },
    getUserEmail() {
      this.takeUserEmail = true;
      this.continueModal = false;
      // this.$router.push("/confirm-plan-information");
      // this.openNotifyModal(); 
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
      this.searchTerm = "";
    },
    focusSearchInput() {
      setTimeout(() => {
        document.getElementById("focusSearch").focus();
      }, 100);
    },
    searchingEmployers(e) {
      if (!this.searchTerm) {
        this.employersCopy = this.employers;
      }

      this.employers = this.employersCopy.filter((data) => {
        // console.log(data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase()));
        return data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase());
      });

      if(this.searchTerm == '') {
        this.employers = this.allEmployers;
        this.employersCopy = this.allEmployers;
        // this.getEmployers();
      }
    },
    selectedEmployer() {
      // this.getEmployers();
      this.employers = this.allEmployers;
      this.employersCopy = this.allEmployers;
      
      // console.log(this.selectedEmployerInfo);

      this.user.searchEmp = this.selectedEmployerInfo.companyName;
      this.empl = this.selectedEmployerInfo;
    },
    openNotifyModal() {
      this.continueModal = false;
      this.notifyModal = true;
      this.data.notify = true;
      this.register();
    },
    async continueFlow() {
        this.loading = true;
      
      this.data = {
        email: this.user.email,
      };
      if (this.empl !== null) {
        this.data.employerId = this.empl.id;
      } else {
        this.data.companyName = this.user.searchEmp;
      }
      if (this.selectedProvider) {
        this.data.providerId = this.selectedProvider.id;
        this.data.locationId = this.selectedProvider.locationId;
      }

      // console.log("dataaa", this.data, this.empl, this.user.searchEmp);

        const checkEmail = await this.checkEmail();
        if (!checkEmail.valid && checkEmail.message === 'This email is already registered') {
            const encodedEmail = encodeURIComponent(this.user.email);
            this.$router.push(`/login?email=${encodedEmail}`);
        }

      if (
          (this.empl !== null && this.empl.status === "inactive") || 
          (this.empl !== null && this.empl.status === "pending") || 
          (this.empl === null && this.user.searchEmp)
        ) {
        this.empl = null;
        this.data.notify = true;
        this.data.ipAddress = this.ipAddress;
        this.continueModal = true;

        this.$store.commit('setRegistrationData', this.data)
      } else {
        // this.empl = null;
        // this.register();
        this.$store.commit('setRegistrationData', this.data)
        // this.$router.push("/confirm-plan-information");

        // Needs to store data entered data in here as well to avoid data loss
        // regardless if Elgibility fails or not
        
        try {
            if (checkEmail.valid) {
                await this.register();
            }
        } catch(err) { 
            // console.log(err)  
            // this.$swal(err.response.data, "", "error");
            this.somethingWrong = true;
        }
      }
        
        this.loading = false;

        // if ((this.empl !== null && this.empl.status === "inactive") || (this.empl === null && this.user.searchEmp)) {
        // } 
    },
    async notifyMe() {
        this.continueModal = false;
        this.loading = true;

        try {
            const checkEmail = await this.checkEmail();
            if (!checkEmail.valid && checkEmail.message === 'This email is already registered') {
                const encodedEmail = encodeURIComponent(this.user.email);
                this.$router.push(`/login?email=${encodedEmail}`);
            } else if (checkEmail.valid) {
                await this.register();
                this.notifyModal2 = true;
            }

        } catch(err) { 
            this.somethingWrong = true;
        }

        this.loading = false;
    },
    confirmEligibility() {
      this.employerCheck = false;
    },
    navigateTo(link) {
      this.$router.push({ name: link });
    },
    toProd() {
      window.location = "https://apaly.net";
    },
    delayCheckmail() {
      clearTimeout(this.typingTimuot);
      this.typingTimuot = setTimeout(() => {
        this.user.email = this.user.email.trim();
        this.checkEmail();
      }, 500);
    },
    async checkEmail() {
      this.user.email = this.user.email.toLowerCase();

      const isEmailValid = this.emailRules.every(
        (rule) => rule(this.user.email) === true
      );
      if (!isEmailValid) {
        this.emailErrorMessage =
          "Please enter a valid e-mail";
        return;
      }
      return api()
        .post(`/member/app/validate/email`, { email: this.user.email })
        .then((res) => {
          if (res.data.valid) {
            // this.emailStatus = true;
            // this.emailErrorMessage = null;
          } else {
            // this.emailStatus = false;
            // this.emailErrorMessage = res.data;
          }
          return res.data
        })
        .catch((err) => {
          if (err.status == 403) {
            this.emailErrorMessage = err.response.data;
            // this.emailStatus = false;
          } else if (err.status == 400) {
            this.emailErrorMessage = err.response.data;
            // this.emailStatus = false;
          }
          return false;
        });
    },
    checkPassword() {
      if (this.user.password !== this.user.confirmPassword) {
        this.passwordMatches = false;
      } else {
        this.passwordMatches = true;
      }
    },
    async register() {
      let companyFilter = this.$route.query.company;
      let locationFilter = this.$route.query.location;
      let query = companyFilter || locationFilter ? "?" : "";
      if (companyFilter) query += `company=${companyFilter}`;
      if (locationFilter)
        query += `${companyFilter ? "&" : ""}location=${locationFilter}`;
      this.$refs.form.validate();

      if (this.valid) {
        if (!this.registerSemafor) return;

        this.loading = true;
        this.registerSemafor = false;
        // try {
        //   var res = await api().post(`/member/app/signup${query}`, this.data)
        //   if (res) {
        //     this.$cookies.set("token", res.data.accessToken);
        //     res.data.type = 'dpc-member';
        //     localStorage.setItem("user", JSON.stringify(res.data));

        //     if(this.empl.status == 'active'){
        //       await this.$store.dispatch('getEmployerPlans')
        //     }
        //   }
        //   this.registerSemafor = true;
        // } catch(err){
        //   console.log(err)
        //   this.registerSemafor = true
        // }
        await api()
          .post(`/member/app/signup${query}`, this.data)
          .then(async (res) => {
            if (res) {
              // res.data.type = 'dpc-member'; // Why do this? K.N
              // localStorage.setItem("user", JSON.stringify(res.data)); // We'll no longer do this

              if (!res.data.accountExists) {
                    // Set token
                    this.$cookies.set("token", res.data.accessToken);
                    
                    // Dispatch memberInfo
                    await this.$store.dispatch('asyncGetMemberInfo');

                    // Dispatch memberInfo
                    this.$store.dispatch('getMemberInfo');

                    // TO SHOW SIDEBAR
                    this.$store.commit("showSidebar", true);
              }
             

              if(this.empl && this.empl.status == 'active'){
                if (!this.selectedProvider) {
                    // Go To marketplace
                    this.$router.push("/marketplace?selectProvider=true");
                } else {
                    // Confirm eligibility
                    this.$router.push('/confirm-plan-information').then(() => {
                        window.location.reload();
                    });
                }

                this.$store.dispatch('getEmployerPlans')
              } else {
                this.continueModal = false;
                this.selectedEmployerInfo = null;
                this.user.email = null
              }
              
              this.registerSemafor = true;
              // this.checkEmailDialog = true;

              // console.log("Registration, successful: ", res.data);

              // Mixpanel Integrations
              if(res.data.id && this.selectedProvider) {
                // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
                const account_creation_timestamp = new Date().toISOString();
                // Track Account Creation: SUCCESS
                Mixpanel.track("member_account_created", {
                  user_id: res.data.id, 
                  timestamp: account_creation_timestamp,
                  success: true,
                  error_reason: "",
                });
              }
              this.loading = false;

            }
          })
          .catch((err) => {
            console.log(2);
            // this.$swal(err.response.data, "", "error");
            this.somethingWrong = true;
            this.registerSemafor = true;

            if(this.memberInfo.id) {
              // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
              const account_creation_timestamp = new Date().toISOString();
              // Track Account Creation: FAILED
              Mixpanel.track("member_account_created", {
                user_id: null, // Since account creation failed.
                timestamp: account_creation_timestamp,
                success: false,
                error_reason: err.response.data.message ? err.response.data.message : err.response.data,
              });
            }
          })
          .finally(() => {
            this.registerSemafor = true // IDK what this is for. K.N
            this.loading = false;
          })
      }
    },
    signUp() {
      // this.register();
      this.$store.commit('setRegistrationData', this.data)
      this.$router.push('/confirm-info')

    },
    openPdfModal() {
      // Open in new Tab
      window.open('https://apaly.app/tou/', '_blank');

      // const url = "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/TermsAndConditionsMember.pdf";
      // this.pdfUrl = url;
      // this.pdfDialog = true;

      // const TermsAndConditionsMember = "https://drive.google.com/viewerng/viewer?embedded=true&url=apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/TermsAndConditionsMember.pdf";
      // window.open(TermsAndConditionsMember, "_blank");
    },
    getPlans() { },
    closeMemberPlanModal() {
      this.membersPlanModalStatus = false;
    },
    checkNoData(event) {
      this.showEmployer = true;
      const inputText = "";
      if (event.target) {
        inputText = event.target.value;
      }
      if (inputText.trim() !== "") {
        const matchedEmployer = this.employers.find(
          (emp) =>
            emp.companyName.toLowerCase() === inputText.toLowerCase() ||
            emp.companyName.toLowerCase().startsWith(inputText.toLowerCase())
        );
        if (matchedEmployer) {
          this.user.searchEmp = matchedEmployer;
        } else {
          this.user.searchEmp = {
            companyName: inputText,
            status: "inactive",
          };
        }
      }
      //   this.searchEmp = '';
    },
    // setEmployer(item) {
    //   this.user.searchEmp = item.companyName;
    //   this.empl = item;
    //   this.showEmployer = false;
    // },
    getEmployers() {
      api()
        .get("/member/app/potential/employers")
        .then((res) => {
            console.log('res');
          this.employers = res.data;
          this.employersCopy = res.data;
          this.allEmployers = res.data;
        });
    },
  },
  created() {
    // localStorage.clear();
    this.$cookies.remove("token");
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Register", // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
    this.selectedProvider = this.$store.getters.getSelectedProvider;
    this.employerFromMarketplace = this.$store.getters.getSelectedEmployer;

    // Auto update employer field
    if (this.employerFromMarketplace) {
        this.selectedEmployerInfo = this.employerFromMarketplace;
        this.user.searchEmp = this.selectedEmployerInfo.companyName;
        this.empl = this.selectedEmployerInfo;
    }
    // console.log('selectedProvider', this.selectedProvider)
    this.getEmployers();

    // Redirect
    localStorage.setItem('redirect', '/home');

    // Add Fam: No
    localStorage.setItem("addFam", "no");
  },
};
</script>
<style scoped lang="scss">


.hide-desktop{
  display: none !important;
}
label{
    color: #333333 !important;
    font-weight: 400; 
  }
.mobile-footer{
  margin-top: 0px !important;
}
.subtitle-space {
  margin-bottom: 25px;
}



.mr-logo {
  margin-right: 22vw;
  margin-left: 4px;
}

.email-sent {
  color: white;
}

.provider-logo {
  height: 56px;
  width: 80px;
  background-color: white;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  left: 8px;
  top: 40px;
  background-size: contain;
  background-position: center;
}

.autocomplete-list {
  font-size: 14px;
  background-color: white;
  position: absolute;
  z-index: 9;
  border: 1px solid rgb(219, 211, 211);
  border-radius: 8px;
  overflow-y: scroll;
  padding: 10px;
}

.label-color {
  color: #333333 !important;
  font-weight: bold;

}

.small-color {
  color: gray !important;
}







.register-page-logo {
  text-align: center;
  position: absolute;
  left: 30px;
}

.register-page-title {
  margin-top: 20px;
  text-align: center;
}

.register-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}

.register-page-error-box {
  padding: 20px 140px;
}

.register-page-header {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;

  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }

  &-logo {
    img {
      width: 120px;
    }
  }

  &-text {
    color: white;
  }

  &-footer-text {
    color: white;
  }
}

.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-button {
  margin-top: 45px;
  margin-bottom: 40px;
  width: 230px;
  color: white;
  display: flex;
  border-radius: 8px;

  .primary {
    flex: 0.5;
  }
}

.link-text {
  color: #0069f3;
  cursor: pointer;
}
.logo-style{
    display: flex !important;
  }

@media only screen and (max-width: 600px) {
 
  .hide-desktop{
  display: flex !important;
}
  .hide-mobile{
    display: none !important;
  }
  .apaly-logo{
    height: 40px;
    width: 78px;
  }
  .mr-logo {
    margin-right: 0px;
  margin-left: 0px;
}
  .logo-style{
    display: flex !important;
    justify-content: space-between;
  }
  .mobile-footer{
  margin-top: 30vh !important;
}
  .form-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subtitle-space {
    margin-bottom: 10px;
  }

  .provder-div {
    padding-bottom: 15px;
  }


  

  .router-view {
    padding-top: 0px !important;
  }

  .register-page-logo {
    position: inherit;
  }

  .register-page {
    padding: 20px;
    margin-top: 0px;
    // border: 1px solid red;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .register-page-header {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
  }

  .register-page-container {
    width: 100%;
  }

  .register-page-footer {
    padding: 0;
    margin-top: 20px;
  }

  .register-page-error-box {
    padding: 10px 0;
  }

  .authentication-page-right-side-form {
    padding: 0;
    width: 100%;
  }

  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
